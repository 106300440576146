import React from "react";
import { graphql } from "gatsby";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  About,
  ImageCollection,
  Reviews,
  Contact,
} from "@fishrmn/fishrmn-components";
import Markdown from "markdown-to-jsx";
import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Home</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={
            <HeroElement
              header={data.businessName}
              tagline={""}
              images={[data.heroImages.map(({ url }) => url)[1]]}
              showMap={false}
              ctas={[
                data.ctas.phone,
                {
                  link: "https://ordering.chownow.com/order/24341/locations",
                  text: "Order Online",
                  internal: false,
                  className: "chownow-order-online",
                },
                {
                  link: "https://apps.apple.com/us/app/philly-steak/id1531996752?app",
                  text: "Download our iOS app",
                  internal: false,
                },
                {
                  link: "https://play.google.com/store/apps/details?id=com.chownow.phillysteaktogoone&hl=en_US",
                  text: "Download our Android App",
                  internal: false,
                }
              ]}
              ctaInverted={false}
              ctaColor={"primary"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={"100vh"}
              gutter={false}
            />
          }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <About
                content={<Markdown>{data.aboutMarkdown}</Markdown>}
                header={`Welcome to ${data.businessName}`}
                centerContent={true}
                headerAs={"h1"}
                withContainer={true}
              />
            </Grid.Column>
          </Grid>
          {/* <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                as={"gallery"}
                centered={false}
                cutUnevenRows={true}
                disable={"both"}
                header={"Photos"}
                images={data.galleryImages.map(({ url }) => url)}
                numberOfRowDisplayItems={4}
              /> 
            </Grid.Column>
          </Grid> */}
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Reviews
                reviews={data.reviews}
                as={"horizontalItem"}
                buttonBasic={false}
                buttonSize={"medium"}
                cardInlinePhoto={false}
                centerHeader={true}
                header={"Reviews"}
                imageCircular={true}
                imageSize={"small"}
                itemButtonFloat={false}
                itemCenterContent={false}
                itemsDivided={false}
                itemsPerRow={3}
                linkAs={"none"}
                onlyEvenRows={true}
                showImage={true}
                verticalItemAlign={"center"}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable className="component-section-container">
            <Grid.Column width={16}>
              <Contact
                phoneNumber={data.phone}
                email={data.email}
                address={{
                  street: data.address.street,
                  city: data.address.city,
                  state: data.address.state,
                  zip: data.address.zip,
                }}
                hours={data.hours}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            phone {
              link
              text
              internal
            }
          }
          businessName
          aboutMarkdown
          reviews {
            name
            content
            link
          }
          phone
          email
          address {
            street
            city
            state
            zip
          }
          hours {
            open
            close
            day
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url
          }
          heroImages {
            url
          }
        }
      }
    }
  }
`;
